import React, { Fragment } from 'react'
import { compareNumbers } from './sort-functions'

export function returnSortedDateString(dateArray) {
  if (!dateArray && dateArray.length == 0) return false
  let date = ''
  // Ensure dates are ordered so that the earlest date appears first
  dateArray
    .sort((a, b) => {
      return compareNumbers(a.year, b.year, false)
    })
    .map((child, index) => {
      if (!child?.year) return false
      let humanDate = returnHumanDate(child)

      if (!humanDate) return false
      if (index > 0) date += '–'
      date += humanDate
    })
  return date
}

export const returnHumanDateFromTimestamp = (timestamp) => {
  if (!timestamp) return false
  const stamp = new Date(timestamp).toLocaleString()
  if (!stamp) return false
  const split = stamp.split(', ')[1]
  return (
    returnHumanDateFromSlashDate(stamp.split(', ')[0]) +
    ' at ' +
    split.split(':')[0] +
    ':' +
    split.split(':')[1] +
    ' ' +
    split.split(' ')[1]
  )
}

export const returnDateAndTimeObjFromTimestamp = (timestamp) => {
  if (!timestamp) return false
  const stamp = new Date(timestamp).toLocaleString()
  if (!stamp) return false
  const split = stamp.split(', ')[1]
  return {
    date: returnHumanDateFromSlashDate(stamp.split(', ')[0]),
    time:
      split.split(':')[0] +
      ':' +
      split.split(':')[1] +
      ' ' +
      split.split(' ')[1],
  }
}

/**
 * Format month number as human-readable text.
 * @param {number} month Number from 1-12 that represents a month.
 * @param {string} type  String that represents how the human readable month will be displayed.
 *                       values: 'short' (default), '2-digit', 'long', 'narrow', 'numeric'
 * @return Formatted string.
 */
export function returnHumanMonth(month, type = 'long') {
  const m = parseInt(month)
  if (!m) return false
  if (typeof m !== 'number') return false
  if (m < 1 || m > 12) return false
  if (type && typeof type !== 'string') return false

  const date = new Date()
  //Ensure the day is at the beginning of the month to account for end of month transitions
  //Otherwise, a month of 2 will return 'Mar'
  date.setDate(1)
  //Months are 0-based integers
  date.setMonth(m - 1)
  const monthString = date.toLocaleString('default', { month: type })

  return monthString
}

/**
 * Format numeric day, month, and year as human-readable text.
 * @param {object} date       Bbject of key/value pairs including year, month, day, and dateQualifier
 * @param {string} type       String that represents how the human readable month will be displayed.
 *                            values: 'short' (default), '2-digit', 'long', 'narrow', 'numeric'
 * @return Formatted string.
 */
export function returnHumanDate(
  date = { year: '', month: '', day: '', dateQualifier: '' },
  type = 'long'
) {
  if (date.year == '' || isNaN(date.year)) return null

  let day = isNaN(date.day) || !date.month || isNaN(date.month) ? '' : date.day
  let month =
    date.month == '' || isNaN(date.month)
      ? ''
      : returnHumanMonth(date.month, type) + ' '
  let year = date.year || ''
  let qual1 =
    date.dateQualifier &&
    typeof date.dateQualifier == 'string' &&
    date.dateQualifier !== '?'
      ? date.dateQualifier + ' '
      : ''
  let qual2 =
    date.dateQualifier &&
    typeof date.dateQualifier == 'string' &&
    date.dateQualifier !== 'ca.'
      ? ' ' + date.dateQualifier
      : ''

  if (day && month && year) day = day + ', '
  return qual1 + month + day + year + qual2
}

/**
 * Identify the time difference between two years
 * @param {object} startDate    String representing the day of the earlier date
 * @param {object} endDate      String representing the day of the later date
 * @return Formatted string.
 */

export function returnDateDifference(
  startDate = { year: '', month: '', day: '', qualifier: '' },
  endDate = { year: '', month: '', day: '', qualifier: '' }
) {
  if (!startDate.year) return false
  //if (typeof startDate.year !== 'string') return false
  //if (startDate.day && typeof startDate.day !== 'string') return false
  //if (startDate.month && typeof startDate.month !== 'string') return false
  if (startDate.day && !startDate.month) return false
  if (startDate.qualifier && typeof startDate.qualifier !== 'string')
    return false

  //if (endDate.day && typeof endDate.day !== 'string') return false
  //if (endDate.month && typeof endDate.month !== 'string') return false
  //if (endDate.year && typeof endDate.year !== 'string') return false
  if (endDate.day && !endDate.month) return false
  if (endDate.qualifier && typeof endDate.qualifier !== 'string') return false

  let preface = ''
  if (
    !startDate.day ||
    !endDate.day ||
    startDate.qualifier ||
    endDate.qualifier
  )
    preface = 'about '
  const startDay = startDate.day || 1
  const startMonth = startDate.month || 1
  const startYear = startDate.year
  const endDay = endDate.day || 1
  const endMonth = endDate.month || 1
  const endYear = endDate.year || new Date().getFullYear()

  const begin = new Date(startMonth + '/' + startDay + '/' + startYear),
    end = new Date(endMonth + '/' + endDay + '/' + endYear)
  const difference = Math.floor(end.getTime() - begin.getTime())

  const day = 1000 * 60 * 60 * 24
  const days = Math.floor(difference / day)
  const months = Math.floor(days / 31)
  const years = Math.floor(months / 12)
  const yearplural = years > 1 ? 's' : ''

  let message = ''
  if (years > 0) {
    message = preface + years + ' year' + yearplural
  } else {
    message = 'less than 1 year'
  }

  return message
}

export function returnActiveFromDates(
  endDate = { year: '', month: '', day: '', qualifier: '' },
  startDate = { year: '', month: '', day: '', qualifier: '' },
  label = 'Active from '
) {
  if (!startDate && !endDate) return false
  if (startDate && typeof startDate !== 'object') return false
  if (endDate && typeof endDate !== 'object') return false

  let established, abolished
  if (!startDate) established = '??'
  else established = returnHumanDate(startDate)
  if (!endDate) abolished = 'Now'
  else abolished = returnHumanDate(endDate)

  return (
    <>
      {label} {established}
      {'–'}
      {abolished}
    </>
  )
}

// Javascript Date function is bad at parsing...
// ...so we should parse this ourselves
const parseISOString = (s) => {
  if (!s) return new Date()
  if (parseInt(s)) return new Date(s)
  var b = s.split(/\D+/)
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
}

const adjustForTimezone = (date, offset) => {
  if (!offset) offset = date.getTimezoneOffset()
  offset = offset * 60000

  date.setTime(date.getTime() + offset)
  return date
}

export function timeSince(date, post = 'ago') {
  let curr = new Date()
  const offset = curr.getTimezoneOffset()
  let d = adjustForTimezone(new Date(parseISOString(date)), offset)
  var seconds = Math.floor((curr - d) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return (
      Math.floor(interval) +
      ` year${Math.floor(interval) === 1 ? '' : 's'}` +
      ' ' +
      post
    )
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return (
      Math.floor(interval) +
      ` month${Math.floor(interval) == 1 ? '' : 's'}` +
      ' ' +
      post
    )
  }
  interval = seconds / 86400
  if (interval > 1) {
    return (
      Math.floor(interval) +
      ` day${Math.floor(interval) == 1 ? '' : 's'}` +
      ' ' +
      post
    )
  }
  interval = seconds / 3600
  if (interval > 1) {
    return (
      Math.floor(interval) +
      ` hour${Math.floor(interval) == 1 ? '' : 's'}` +
      ' ' +
      post
    )
  }
  interval = seconds / 60
  if (interval > 1) {
    return (
      Math.floor(interval) +
      ` minute${Math.floor(interval) == 1 ? '' : 's'}` +
      ' ' +
      post
    )
  }
  if (interval > 0) {
    return (
      Math.floor(seconds) +
      ` second${Math.floor(seconds) == 1 ? '' : 's'}` +
      ' ' +
      post
    )
  }
  return 'just now'
}

function dateIsValid(date) {
  return !Number.isNaN(new Date(date).getTime())
}

export function localTime(date) {
  let d = new Date(date)
  d = d - d.getTimezoneOffset() * 60000
  d = new Date(d)
  if (dateIsValid(d))
    return d
      .toISOString()
      .split(' ')[0]
      .match(/\d\d:\d\d:\d\d/)
}

export function returnHumanDateFromSlashDate(date) {
  if (!date) return false
  let dateObject = { year: '', month: '', day: '', dateQualifier: '' }
  const dateArray = date.split('/')

  if (dateArray.length == 1) {
    if (dateArray[0].indexOf('ca. ') > -1) {
      dateObject.dateQualifier = 'ca.'
      dateObject.year = dateArray[0].replace('ca. ', '')
    } else {
      dateObject.year = dateArray[0]
    }
  }
  if (dateArray.length == 2) {
    dateObject.month = dateArray[0]
    if (dateArray[1].indexOf('ca. ') > -1) {
      dateObject.dateQualifier = 'ca.'
      dateObject.year = dateArray[1].replace('ca. ', '')
    } else {
      dateObject.year = dateArray[1]
    }
  }
  if (dateArray.length == 3) {
    dateObject.month = dateArray[0]
    dateObject.day = dateArray[1]
    if (dateArray[2].indexOf('ca. ') > -1) {
      dateObject.dateQualifier = 'ca.'
      dateObject.year = dateArray[2].replace('ca. ', '')
    } else {
      dateObject.year = dateArray[2]
    }
  }
  return returnHumanDate(dateObject)
}

/**
 *
 * @param {*} record
 */
export function returnAllDatesFromRecordData(record) {
  if (!record) return false
  let dateArray = []
  if (record.birthDate) {
    dateArray.push({
      type: 'birthDate',
      label: 'Born',
      date: returnSortedDateString([record.birthDate]),
    })
  }
  if (record.deathDate) {
    dateArray.push({
      type: 'deathDate',
      label: 'Died',
      date: returnSortedDateString([record.deathDate]),
    })
  }
  if (record.inclusiveStartDate || record.inclusiveEndDate) {
    dateArray.push({
      type: 'inclusiveDates',
      label: 'Collected',
      date: returnSortedDateString([
        record.inclusiveStartDate,
        record.inclusiveEndDate,
      ]),
    })
  }
  if (record.coverageStartDate || record.coverageEndDate) {
    dateArray.push({
      type: 'coverageDates',
      label: 'Covers',
      date: returnSortedDateString([
        record.coverageStartDate,
        record.coverageEndDate,
      ]),
    })
  }
  if (record.productionDates) {
    dateArray.push({
      type: 'productionDates',
      label: 'Produced',
      date: returnSortedDateString(record.productionDates),
    })
  }
  if (record.broadcastDates) {
    dateArray.push({
      type: 'broadcastDates',
      label: 'Broadcast',
      date: returnSortedDateString(record.broadcastDates),
    })
  }
  if (record.releaseDates) {
    dateArray.push({
      type: 'releaseDates',
      label: 'Released',
      date: returnSortedDateString(record.releaseDates),
    })
  }
  return dateArray
}

/**
 *
 * @param {*} record
 */
export function returnDateArrayFromRecordData(record) {
  if (!record) return false
  let dateArray = {}
  if (record.inclusiveStartDate || record.inclusiveEndDate) {
    dateArray.inclusive = returnSortedDateString([
      record.inclusiveStartDate,
      record.inclusiveEndDate,
    ])
  }
  if (record.coverageStartDate || record.coverageEndDate) {
    dateArray.coverage = returnSortedDateString([
      record.coverageStartDate,
      record.coverageEndDate,
    ])
  }
  if (record.productionDates) {
    dateArray.production = returnSortedDateString(record.productionDates)
  }
  if (record.broadcastDates) {
    dateArray.broadcast = returnSortedDateString(record.broadcastDates)
  }
  if (record.releaseDates) {
    dateArray.release = returnSortedDateString(record.releaseDates)
  }
  return dateArray
}

export const returnHMFromMinutes = (minutes) => {
  if (!minutes) return false
  const num = minutes
  const hours = num / 60
  const rhours = Math.floor(hours)
  const min = (hours - rhours) * 60
  const rmin = Math.round(min)
  return { hours: rhours, minutes: rmin }
}

export const returnHMSFromSeconds = (seconds) => {
  if (!seconds) return '00:00:00'

  const hours = String(Math.floor(seconds / 3600)).padStart(2, '0')
  seconds %= 3600
  const minutes = String(Math.floor(seconds / 60)).padStart(2, '0')
  const rseconds = String(seconds % 60).padStart(2, '0')

  return `${hours}:${minutes}:${rseconds}`
}
