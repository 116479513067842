import React, { useContext } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import { returnHumanDate } from '../../../helper-functions/date-functions'
import { description } from '../../settings/RecordPropTypes'
import { returnSortedOrgNames } from '../../Pages/AuthorityPages/use_organizations'
import Highlighter from '../../Highlighter/Highlighter'
import SearchContext from '../../../contexts/SearchContext'
import { useSearchParams } from 'react-router-dom'

const Title = ({ record, truncate, ...props }) => {
  if (!record) return false
  const { searchQuery } = useContext(SearchContext) || {}
  const [searchParams] = useSearchParams()
  const q = searchParams?.get('q') || ''
  const title = searchParams?.get('title') || ''
  const search = `${q} ${title}`

  return (
    <h2
      className={[
        'search-result--title',
        //
        'clearfix',
        'display-inline-block',
        'font-sans-md',
        'hover:text-underline',
        'line-height-sans-3',
        'margin-bottom-05',
        'maxw-tablet',
        'text-bold',
        'text-primary',
      ].join(' ')}
    >
      <Highlighter search={search}>{returnRecordTitle(record)}</Highlighter>
      {/* <Highlighter search={search}>{returnRecordDate(record)}</Highlighter> */}
    </h2>
  )
}

Title.defaultProps = {
  truncate: '',
}

Title.propTypes = {
  /**
   * The entire data object for a single record
   */
  record: description,
  truncate: PropTypes.string,
}

export default Title

export const returnRecordDate = (record) => {
  if (
    !record.productionDates &&
    !record.inclusiveStartDate &&
    !record.inclusiveEndDate
  )
    return false
  let date = ''
  if (record.productionDates) {
    date += ', ' + returnHumanDate(record.productionDates[0])
  }
  if (record.inclusiveStartDate || record.inclusiveEndDate) {
    const start = returnHumanDate(record.inclusiveStartDate)
    const end = returnHumanDate(record.inclusiveEndDate)
    if (start == end) date += ', ' + start
    else date += ', ' + (start ? start : '?') + '–' + (end ? end : 'Now')
  }
  return date
}

export const returnRecordTitle = (record) => {
  let title = ''
  if (record.recordType == 'authority') {
    if (record.authorityType === 'organization') {
      title = `${
        returnSortedOrgNames(record.parent || record, false)[0].heading
      }`
    } else title = record.heading
  } else {
    let subtitle = ''
    if (record.subtitle != null) {
      subtitle = record.subtitle
      title = record.title + ': ' + subtitle
    } else {
      title = record.title
    }
  }
  return title

  /* ${record.organizationNames.length > 1 ? 'Most Recent Name: ' : ''} */
}
